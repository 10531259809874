import React, { useState } from "react";
import { FaGlobe } from "react-icons/fa";
import { handleGetLanguage, handleSetLanguage } from "../utils/languageStorage"; // Adjust the import path as necessary

const CustomSelect = () => {
    const [isShown, setIsShown] = useState(false);

    const options = [
        { value: "en", label: "EN" },
        { value: "ka", label: "KA" },
    ];

    const handleSelectChange = (value) => {
        // setSelectedOption(value);
        handleSetLanguage(value);
        window.location.reload();
    };
    const language = handleGetLanguage();
    return (
        <div className="relative">
            <button
                onClick={() => setIsShown((prev) => !prev)}
                className="md:h-9 h-8 flex items-center bg-transparent border border-black dark:border-white dark:hover:bg-gray-600 rounded-md shadow-md p-2 justify-between hover:bg-gray-100"
            >
                <FaGlobe className="dark:text-white md:size-4 size-[10px]" />
                <span className="ml-2 dark:text-white md:text-base text-[10px]">{language}</span>
            </button>

            {isShown && (
                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md  dark:text-black">
                    {options.map((option) => (
                        <button
                            key={option.value}
                            onClick={() => {
                                handleSelectChange(option.value);
                                setIsShown(false);
                            }}
                            className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-sm"
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
