import React, { useState } from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {useMediaQuery} from "usehooks-ts";

export default function ScrollToTop(){
    let [visible, setVisible] = useState(false)
    const location = useLocation();
    const { id } = useParams();

    // Check if the current page is a product detail page
    const isMobile = useMediaQuery("(max-width: 768px)");
    const isProductDetailPage = location.pathname.startsWith("/product-detail/");

    const toggleVisible = () => { 
        const scrolled = document.documentElement.scrollTop; 
        if (scrolled > 300){ 
          setVisible(true) 
        }  
        else if (scrolled <= 300){ 
          setVisible(false) 
        } 
      }; 

      const scrollToTop = () =>{ 
        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth'
        }); 
    }; 

    window.addEventListener('scroll', toggleVisible); 
    return(
        <>
         <Link to="#" onClick={()=>scrollToTop()} id="back-to-top" className={`back-to-top fixed text-lg rounded-full z-10 ${isProductDetailPage && isMobile ? 'bottom-16' : 'bottom-5'} end-5 size-9 text-center bg-orange-500 text-white justify-center items-center`} style={{display: visible ? 'inline-flex' : 'none'}}><i className="mdi mdi-arrow-up"></i></Link>
        </>
    )
}