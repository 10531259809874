import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";

import { handleGetLanguage } from "../utils/languageStorage";

const ShopItemCard = ({ item }) => {
    const language = handleGetLanguage();

    return (
        <div className="group cursor-pointer relative">
            <Link
                to={`/product-detail/${item.id}`}
                className="overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500 block"
            >
                <img
                    src={
                        item?.images?.[0]?.includes("http")
                            ? item?.images?.[0]
                            : `https://api.onlybrands.ge/storage/${item?.images?.[0]}`
                    }
                    className="group-hover:scale-110 duration-500 w-full"
                    alt=""
                    style={{
                        height: "300px",
                        objectFit: "contain",
                        maxWidth: "100%",
                    }}
                />
            </Link>

            <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                <li className="mt-1 ms-0">
                    <Link
                        to={`/product-detail/${item.id}`}
                        className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                        <FiEye className="size-4" />
                    </Link>
                </li>
            </ul>

            <div className="mt-4">
                <Link
                    to={`/product-detail/${item.id}`}
                    className="hover:text-orange-500 text-lg font-medium"
                >
                    {language === "ka" ? item.name.ka : item.name.en}
                </Link>
                <div className="flex justify-between items-center mt-1">
                    {item?.discounted_price ? (
                        <p>
                            GEL{item?.discounted_price}{" "}
                            {item.price && <del className="text-slate-400">GEL{item?.price}</del>}
                        </p>
                    ) : (
                        <p>GEL{item?.price}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShopItemCard;
