import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiSearch, IoIosArrowDown, IoIosArrowUp } from "../assets/icons/vander";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../utils/axiosInstance";
import { handleGetLanguage } from "../utils/languageStorage";
import { useMediaQuery } from "usehooks-ts";

// RangeSelector Component
const RangeSelector = ({ onSelect, language }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [from, setFrom] = useState("0");
    const [to, setTo] = useState("");
    const [isVisible, setIsVisible] = useState(false);

    const removeQuery = (key) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(key);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
    };

    useEffect(() => {
        if (isVisible) {
            const handler = setTimeout(() => {
                if ((!from || from === "0") && (!to || to === "0")) {
                    removeQuery("price");
                } else {
                    const url = onSelect("price", `${from},${to}`);
                    navigate(url);
                }
            }, 500);

            return () => clearTimeout(handler);
        }
    }, [from, to]);

    return (
        <div className="md:mt-4 pt-3 text-base md:text-xl border-t-2">
            <button
                className="font-medium cursor-pointer w-full  flex justify-between pb-3"
                onClick={() => setIsVisible(!isVisible)}
            >
                {language === "en" ? "Price Range" : "ფასი"}
                <p>{isVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}</p>
            </button>
            {isVisible && (
                <div className="flex flex-col sm:flex-row justify-between items-center mt-2 gap-2">
                    <input
                        type="text"
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                        className="w-full sm:w-[48%] p-1 md:p-2 border rounded mb-2 sm:mb-0 dark:bg-transparent dark:border-gray-800"
                        placeholder={language === "en" ? "From" : "დან"}
                    />
                    <input
                        type="text"
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                        className="w-full sm:w-[48%] p-1 md:p-2 border rounded dark:bg-transparent dark:border-gray-800"
                        placeholder={language === "en" ? "To" : "მდე"}
                    />
                </div>
            )}
        </div>
    );
};

export default function Filter({
    genderQuery,
    brandQuery,
    sizeQuery,
    colorQuery,
    typeQuery,
    removeQuery,
    updateQuery,
    searchQuery,
    categorieQuery,
    productsCount,
    onShowClick,
    loading,
    showCount,
}) {
    const fetchProductFilters = async () => {
        const response = await axiosInstance.get(`/products/filters`);
        if (response.status !== 200) {
            throw new Error("Something went wrong");
        }
        return response.data;
    };

    const fetchCategories = async () => {
        const response = await axiosInstance.get(`/categories`);
        if (response.status !== 200) {
            throw new Error("Something went wrong");
        }
        return response.data;
    };
    const [searchValue, setSearchValue] = useState("");
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [isBrandsVisible, setIsBrandsVisible] = useState(false);
    const [isGenderVisible, setIsGenderVisible] = useState(false);
    const [isAllColorsVisible, setIsAllColorsVisible] = useState(false);
    const [isCategoriesVisible, setIsCategoriesVisible] = useState(false);

    const language = handleGetLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const isFilterOnHomePage = location.pathname === "/";

    const { data, isLoading, error } = useQuery({
        queryKey: ["ProductFilters"],
        queryFn: () => fetchProductFilters(),
    });

    const { data: categoriesData } = useQuery({
        queryKey: ["categories"],
        queryFn: () => fetchCategories(),
    });

    const findQueryWithId = (data, query) => {
        const result = data?.find((item) => item.id === +query);
        return result;
    };

    // const size = findQueryWithId(data?.sizes, sizeQuery);
    const brand = findQueryWithId(data?.brands, brandQuery);
    const categorie = findQueryWithId(categoriesData, categorieQuery);
    // const color = findQueryWithId(data?.colors, colorQuery);

    const translations = {
        en: {
            filter: "Filter",
            search: "Search",
            colors: "Colors",
            brands: "Brand",
            sizes: "Size",
            women: "Women",
            men: "Man",
            kid: "Kid",
            categories: "Categories",
        },
        ka: {
            filter: "ფილტრი",
            search: "ძიება",
            colors: "ფერები",
            brands: "ბრენდი",
            sizes: "ზომა",
            women: "ქალი",
            men: "კაცი",
            kid: "ბავშვი",
            categories: "კატეგორია",
        },
    };

    const t = translations[handleGetLanguage()] || translations.en;

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            navigate(updateQuery("search", searchValue));
        }
    };

    const toggleBrandsVisibility = () => {
        setIsBrandsVisible(!isBrandsVisible);
    };

    const toggleCategoriesVisibility = () => {
        setIsCategoriesVisible(!isCategoriesVisible);
    };

    const handleGenderClick = (genderId) => {
        if (+genderQuery === genderId) {
            navigate(removeQuery("gender"));
        } else {
            navigate(updateQuery("gender", genderId));
        }
    };

    const handleBrandClick = (brandId) => {
        if (brand?.id === brandId) {
            navigate(removeQuery("brands"));
        } else {
            navigate(updateQuery("brands", brandId));
        }
    };

    const handleCategoryClick = (categoryId) => {
        if (categorie?.id === categoryId) {
            navigate(removeQuery("categories"));
        } else {
            navigate(updateQuery("categories", categoryId));
        }
    };

    const isMobile = useMediaQuery("(max-width: 768px)");
    useEffect(() => {
        if (!isMobile) {
            setIsFilterVisible(false);
        }
    }, [isMobile]);

    useEffect(() => {
        if (isFilterVisible) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto"; // Cleanup when component unmounts
        };
    }, [isFilterVisible]);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchValue === "") {
                navigate(removeQuery("search"));
            } else {
                navigate(updateQuery("search", searchValue));
            }
        }, 500); // 1.5 seconds debounce

        return () => clearTimeout(handler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    return (
        <>
            <button
                className={`text-left p-2 rounded-lg border bg-orange-500 text-white md:text-base text-sm dark:bg-gray-700 -rotate-90 whitespace-nowrap mt-5 md:hidden fixed z-[99] top-[30%] ${
                    isFilterVisible
                        ? language === "ka"
                            ? "-left-[29px]"
                            : "-left-3"
                        : language === "ka"
                        ? "-right-8"
                        : "-right-4"
                }`}
                onClick={() => setIsFilterVisible(!isFilterVisible)}
            >
                {t.filter} <span className={"text-[10px]"}>{isFilterVisible ? "▼" : "▲"}</span>
            </button>

            <div className="md:w-1/4  md:mt-0 lg:col-span-3 md:col-span-4  fixed md:sticky  h-full md:h-max md:block  top-[75px]  left-0  z-50 ">
                {isFilterVisible && (
                    <div
                        className="fixed inset-0 bg-[rgba(0,0,0,0.5)] z-40 md:hidden"
                        onClick={() => setIsFilterVisible(false)}
                    ></div>
                )}

                <div
                    className={`rounded shadow dark:shadow-gray-800 bg-white dark:bg-gray-900 max-h-[600px] overflow-y-auto    ${
                        isFilterOnHomePage ? "px-4 pb-4 pt-0" : "p-4"
                    }  w-[calc(100vw-40px)] md:w-full fixed md:sticky overflow-y-auto right-0 top-20 md:block z-50  ${
                        isFilterVisible ? "" : "hidden"
                    }`}
                >
                    {!isFilterOnHomePage && (
                        <div className="flex justify-between items-center">
                            <h5 className="text-xl font-medium mb-3">{t.filter}</h5>
                        </div>
                    )}

                    <div>
                        <button
                            className={`text-base md:text-xl flex items-center justify-between w-full font-medium ${
                                isFilterOnHomePage ? "border-t-0" : "border-t-2"
                            } pt-3`}
                            onClick={() => setIsGenderVisible((prev) => !prev)}
                        >
                            {language === "en" ? "Genders" : "სქესი"}
                            <p>{isGenderVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}</p>
                        </button>
                        {isGenderVisible && (
                            <div className="mt-4  text-base md:text-lg  flex-wrap">
                                {[
                                    { id: 1, label: language === "ka" ? "ქალი" : "Women" },
                                    { id: 2, label: language === "ka" ? "კაცი" : "Men" },
                                    { id: 3, label: language === "ka" ? "ბავშვი" : "Kids" },
                                ].map((gender) => (
                                    <button
                                        onClick={() => handleGenderClick(gender.id)}
                                        key={gender.id}
                                        className="flex items-center cursor-pointer py-2"
                                    >
                                        <div
                                            className={`mr-2 h-4 w-4 border-[1px] ${
                                                +genderQuery === gender.id
                                                    ? "bg-orange-500"
                                                    : "bg-white"
                                            }`}
                                        ></div>
                                        <label
                                            htmlFor={`gender-${gender.id}`}
                                            className={`  hover:text-orange-500 ${
                                                +genderQuery === gender.id
                                                    ? "text-orange-500"
                                                    : "text-gray-700 dark:text-gray-300"
                                            }`}
                                        >
                                            {gender.label}
                                        </label>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="my-4 text-base md:text-xl">
                        <button
                            onClick={toggleCategoriesVisibility}
                            className="w-full  flex justify-between border-t-2 pt-3"
                        >
                            <h5 className="font-medium flex items-center justify-between">
                                {t.categories}
                            </h5>
                            <p>{isCategoriesVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}</p>
                        </button>
                        {isCategoriesVisible && (
                            <ul className="list-none mt-2 flex flex-col gap-3">
                                {categoriesData?.map((item, index) => (
                                    <li
                                        key={index}
                                        className="ms-0 flex items-center cursor-pointer"
                                    >
                                        <button
                                            className="flex items-center"
                                            onClick={() => handleCategoryClick(item.id)}
                                        >
                                            <div
                                                className={`mr-2 h-4 w-4 border-[1px] ${
                                                    categorie?.id === item.id
                                                        ? "bg-orange-500"
                                                        : "bg-white"
                                                }`}
                                            ></div>
                                            <label
                                                htmlFor={`category-${item.id}`}
                                                className={`dark:text-gray-100 hover:text-orange-500 ${
                                                    categorie?.id === item.id &&
                                                    "text-orange-500 dark:text-orange-500"
                                                }`}
                                            >
                                                {item.name[language]}
                                            </label>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="my-4 text-base md:text-xl">
                        <button
                            onClick={toggleBrandsVisibility}
                            className="w-full  flex justify-between border-t-2 pt-3"
                        >
                            <h5 className="font-medium flex items-center justify-between">
                                {t.brands}
                            </h5>
                            <p>{isBrandsVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}</p>
                        </button>
                        {isBrandsVisible && (
                            <ul className="list-none mt-2 flex flex-col gap-3">
                                {data?.brands?.map((item, index) => (
                                    <li
                                        key={index}
                                        className="ms-0 flex items-center cursor-pointer"
                                    >
                                        <button
                                            onClick={() => handleBrandClick(item.id)}
                                            className="flex items-center"
                                        >
                                            <div
                                                className={`mr-2 h-4 w-4 border-[1px] ${
                                                    brand?.id === item.id
                                                        ? "bg-orange-500"
                                                        : "bg-white"
                                                }`}
                                            ></div>
                                            <label
                                                htmlFor={`brand-${item.id}`}
                                                className={`dark:text-gray-100 hover:text-orange-500 ${
                                                    brand?.id === item.id &&
                                                    "text-orange-500 dark:text-orange-500"
                                                }`}
                                            >
                                                {/* <i className="mdi mdi-shopping-outline text-orange-500 me-2"></i> */}
                                                {item.name[language]}
                                            </label>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="mt-4 border-t-2 pt-3">
                        <div
                            className="flex items-center justify-between w-full"
                            onClick={() => setIsAllColorsVisible((prev) => !prev)}
                        >
                            <button className="font-medium text-base md:text-xl flex items-center w-full">
                                {language === "en" ? "Colors" : "ფერები"}:
                            </button>
                            <div
                                className="text-base md:text-xl cursor-pointer"
                                onClick={() => setIsAllColorsVisible((prev) => !prev)}
                            >
                                {isAllColorsVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
                            </div>
                        </div>
                        <ul
                            className={`list-none mt-2 flex gap-4 transition-all duration-300 ${
                                isAllColorsVisible ? "flex-wrap" : "overflow-hidden max-h-0"
                            }`}
                        >
                            {(isAllColorsVisible ? data?.colors : data?.colors?.slice(0, 9))?.map(
                                (item, index) => {
                                    const isSelected = +colorQuery === +item.id;
                                    return (
                                        <li
                                            key={index}
                                            className={`inline-block relative mt-3 ${
                                                isSelected ? "underline" : ""
                                            }`}
                                        >
                                            <Link
                                                to={
                                                    isSelected
                                                        ? removeQuery("colors")
                                                        : updateQuery("colors", item.id)
                                                }
                                                className={`h-6 w-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 inline-flex align-middle`}
                                                style={{ backgroundColor: item?.color_hex }}
                                                title={item.name}
                                            >
                                                {isSelected && (
                                                    <span className="absolute -top-2 -right-2 bg-orange-500 text-white rounded-full text-xs px-1">
                                                        ✔
                                                    </span>
                                                )}
                                            </Link>
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    </div>

                    <RangeSelector language={language} onSelect={updateQuery} />

                    <form className="md:mt-4 md:pt-0 pt-3 border-t-2  ">
                        <div>
                            {/* <label htmlFor="searchname" className="font-medium text-base">
                                {t.search}:
                            </label> */}
                            <div className="relative mt-8 ">
                                <FiSearch className="absolute size-4 top-[9px] end-4 text-slate-900 dark:text-white"></FiSearch>
                                <input
                                    type="text"
                                    className="w-full h-9 pe-10 rounded px-3 border border-gray-100 dark:border-gray-800 focus:ring-0 outline-none bg-white dark:bg-slate-900"
                                    name="s"
                                    id="searchItem"
                                    placeholder={t.search}
                                    onChange={(e) => {
                                        console.log("input value", e.target.value);
                                        setSearchValue(e.target.value);
                                    }}
                                    onKeyPress={handleKeyPress}
                                    defaultValue={searchQuery || searchValue}
                                />
                            </div>
                        </div>
                    </form>

                    <button
                        className={`py-3 w-full text-white  flex flex-nowrap items-center justify-center mt-4 rounded-lg mx-auto sticky -bottom-4 ${
                            loading ? "!bg-orange-300" : "bg-orange-500"
                        }`}
                        disabled={loading}
                        onClick={() => {
                            onShowClick();
                            setIsFilterVisible(false);
                        }}
                    >
                        Show {showCount && <p className="italic"> {`( ${productsCount} )`}</p>}
                    </button>
                </div>
            </div>
        </>
    );
}
