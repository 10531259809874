import { FiMoon, FiSun } from "../assets/icons/vander";
import "react-dropdown/style.css";

import CustomSelect from "./custom-select";

export default function Switcher() {
    let htmlTag = document.getElementsByTagName("html")[0];

    const changeTheme = (e) => {
        if (htmlTag.className.includes("dark")) {
            htmlTag.className = "light";
        } else {
            htmlTag.className = "dark";
        }
    };

    return (
        <div className="flex items-center justify-center md:gap-2 gap-1">
            <div className="">
                <span className="relative inline-block">
                    <input
                        type="checkbox"
                        className="checkbox opacity-0 absolute"
                        id="chk"
                        onChange={(e) => changeTheme(e)}
                    />
                    <label
                        className="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 md:w-14 md:h-8 w-11 h-5"
                        htmlFor="chk"
                    >
                        <FiMoon className="md:size-[18px] size-3 text-yellow-500"></FiMoon>
                        <FiSun className="md:size-[18px] size-3 text-yellow-500"></FiSun>
                        {/*<span className="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] w-7 h-7 transition-transform duration-300"></span>*/}
                        <span
                            className="ball bg-white dark:bg-slate-900 rounded-full absolute md:top-[2px] md:left-[2px] top-[1px] left-[1px] md:w-7 md:h-7 w-[18px] h-[18px] transition-transform duration-300
    md:dark:translate-x-[calc(100%-3px)]"
                        />
                    </label>
                </span>
            </div>

            <div className="md:mb-2">
                <CustomSelect/>
            </div>
        </div>
    );
}
