import React from "react";
import { FacebookShareButton, FacebookIcon } from "react-share";

const ShareButton = ({ referralLink, product, language, text, className }) => {
    return (
        <div>
            <FacebookShareButton
                className={`w-full whitespace-nowrap md:py-2 md:px-5 px-3 py-1  font-semibold tracking-wide align-middle 2xl:text-sm md:text-xs text-[10px] text-center bg-orange-500 text-white rounded-md hover:bg-orange-400 flex gap-2 items-center justify-center ${className}`}
                url={referralLink}
                quote={`Check out this product: ${product.name[language]}`}
            >
                <span>{text}</span>
                <FacebookIcon className={'2xl:size-6 md:size-5 size-4'} round/>
            </FacebookShareButton>
        </div>
    );
};

export default ShareButton;
