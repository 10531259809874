function MobileAddToCartContainer({ handleAddToCart, textOne, handleShopNow, textTwo }) {
    return (
        <div
            className={
                "md:hidden flex flex-col gap-2 bg-white dark:bg-black fixed bottom-0 z-10 w-[100%] left-0 px-4 py-[13px] shadow-[0px_-4px_30px_0px_rgba(0,0,0,0.09)]"
            }
        >
            <button
                onClick={handleAddToCart}
                className="w-full py-2 px-5 inline-block font-semibold tracking-wide align-middle 2xl:text-sm text-xs text-center rounded-md bg-orange-500 hover:bg-orange-400 text-white active:opacity-50"
            >
                {textOne}
            </button>
            <button
                onClick={handleShopNow}
                className="w-full py-2 px-5 inline-block font-semibold tracking-wide align-middle 2xl:text-sm text-xs text-center rounded-md bg-orange-500 hover:bg-orange-400 text-white active:opacity-50"
            >
                {textTwo}
            </button>
        </div>
    );
}

export default MobileAddToCartContainer;
