import { toast } from "sonner";
import axiosInstance from "../utils/axiosInstance";
import { handleGetLanguage } from "../utils/languageStorage";

const langugae = handleGetLanguage();

export const getCart = async () => {
    try {
        const response = await axiosInstance.get("/cart");
        return response.data;
    } catch (error) {
        toast.error("Failed to fetch cart.");
        throw new Error(error?.message[langugae] || "Failed to get cart items!");
    }
};
export const checkQuantity = async (item) => {
    try {
        const response = await axiosInstance.get(
            `/products/check-quantity/${item.id}?colorId=${item.colore}&quantity=${item.quantity}`
        );
        return response.status;
    } catch (error) {
        toast.error(error.response.data[langugae]);
        return error.response.status;
    }
};

export const addItemCart = async (item) => {
    try {
        const response = await axiosInstance.post("/cart", item);
        toast.success(response.data.message[langugae]);
        return response.data;
    } catch (error) {
        console.log(error);
        toast.error(error.response?.data?.message[langugae]);
        throw new Error(error?.message[langugae] || "Failed to add new item!");
    }
};

export const removeItemCart = async (item) => {
    try {
        const response = await axiosInstance.delete(`/cart/${item.id}`, {
            data: { size: item.size, color: item.color },
        });
        toast.success(response?.data?.message[langugae]);
        return response.data;
    } catch (error) {
        toast.error(error.response?.data?.message[langugae]);
        throw new Error(error?.message[langugae] || "Failed to remove item!");
    }
};

export const updateItemQuantity = async (item) => {
    try {
        const response = await axiosInstance.put(`/cart/${item.id}`, {
            quantity: item.quantity,
        });
        toast.success(
            langugae === "ka"
                ? "რაოდენობა წარმატებით განახლდა"
                : "Item quantity updated successfully!"
        );
        return response.data;
    } catch (error) {
        toast.error(error.response?.data?.message[langugae]);
        throw new Error(error?.message[langugae] || "Failed to update item quantity!");
    }
};
