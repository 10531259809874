import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import { handleGetLanguage } from "../utils/languageStorage";
import CategorniesMenuItem from "./CategoriesMenuItem";
import { FiChevronRight } from "react-icons/fi";

function CategoriesMenu({ categoriesQuery }) {
  const menuRef = useRef(null);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const updateQuery = (key, value, targetPath) => {
    const searchParams = new URLSearchParams(location.search);

    let pathname = targetPath || location.pathname;

    if (pathname.endsWith("/")) {
      pathname = pathname.slice(0, -1);
    }

    return `${pathname}?${searchParams.toString()}`;
  };

  const language = handleGetLanguage();

  const fetchCategories = async () => {
    const response = await axiosInstance.get(`/categories`);
    if (response.status !== 200) {
      throw new Error("Something went wrong");
    }
    return response.data;
  };

  const { data: categoriesData } = useQuery({
    queryKey: ["categories"],
    queryFn: () => fetchCategories(),
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleOutsideClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const translations = {
    en: {
      categories: "Categories",
    },
    ka: {
      categories: "კატეგორიები",
    },
  };

  const t = translations[language] || translations.en;

  return (
    <li ref={menuRef} className={`parent-menu-item flex items-center`}>

      <ul
        className={`min-w-max bg-white py-6 ml-5 rounded-md shadow-lg absolute top-[100%] -left-4 z-10 transition-all dark:bg-slate-900 uppercase ${
          isOpen ? "block" : "hidden"
        }`}
      >
        {categoriesData?.map((category, i) => (
          <CategorniesMenuItem
            key={i}
            category={category}
            language={language}
            updateQuery={updateQuery}
            categoriesQuery={categoriesQuery}
          />
        ))}
      </ul>
    </li>
  );
}

export default CategoriesMenu;
