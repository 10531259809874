import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Tagline from "../../../components/tagline";
import Navbar from "../../../components/navbar";
import ProductDetail from "../../../components/product-detail";
import ArrivalItem from "../../../components/arrival-item";
import Footer from "../../../components/footer";
import ScrollToTop from "../../../components/scroll-to-top";
import axiosInstance from "../../../utils/axiosInstance";
import { handleGetLanguage } from "../../../utils/languageStorage";
import Loading from "../../../components/loading";
import ImageModal from "../../../components/ImageModal";
import { IoIosArrowBack, IoIosArrowForward, IoMdResize } from "react-icons/io";
import { Helmet } from "react-helmet";
import useUserData from "../../../store/userStore";
import useProductData from "../../../store/productStore";

// **ImageCarousel Component Defined Within This File**
const ImageCarousel = ({ images, setMainImage, mainImage }) => {
    const prefix = `https://api.onlybrands.ge/storage/`;

    return (
        <div className="flex flex-row lg:flex-col gap-3 overflow-x-auto lg:overflow-visible">
            {images.map((item, index) => (
                <button
                    key={index}
                    onClick={() => setMainImage(item)}
                    className={`flex-shrink-0 p-1 ${
                        mainImage === item ? "border-2 border-blue-500" : ""
                    }`}
                >
                    <img
                        src={item.includes("http") ? item : prefix + item}
                        alt={`Thumbnail ${index}`}
                        className="h-16 w-16 sm:h-20 sm:w-20 object-cover hover:scale-105 transition-transform duration-150 rounded-md"
                    />
                </button>
            ))}
        </div>
    );
};

const fetchData = async (id) => {
    const response = await axiosInstance.get(`/products/${id}`);
    if (response.status !== 200) {
        throw new Error("Something went wrong");
    }
    return response.data;
};

export default function ProductDetailThree() {
    const location = useLocation();
    const language = handleGetLanguage();
    const { user } = useUserData((state) => state);
    const { setProductData } = useProductData((state) => state);
    const prefix = `https://api.onlybrands.ge/storage/`;
    const referralLink = `https://onlybrands.ge${location.pathname}?referral_code=${user.referral_code}`;

    const translations = {
        en: {
            loading: "Loading...",
            Onlybrands: "Onlybrands",
            store: "Store",
            error: "Something went wrong",
            freeShipping: "Free delivery throughout Georgia",
            buyOnlyOriginal: "Purchase only the original",
        },
        ka: {
            loading: "იტვირთება...",
            Onlybrands: "მხოლოდ ბრენდები",
            store: "მაღაზია",
            error: "რამე არასწორედაა",
            freeShipping: "უფასო მიწოდება საქართველოს მასშტაბით",
            buyOnlyOriginal: "შეიძინე მხოლოდ ორიგინალი",
        },
    };
    const t = translations[language] || translations.en;
    const [selectedImage, setSelectedImage] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [activeButton, setActiveButton] = useState(null); // "prev" or "next"

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    const { id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, error } = useQuery({
        queryKey: ["singleProduct", id],
        queryFn: () => fetchData(id),
    });

    const product = data?.product;

    useEffect(() => {
        if (product && product.images && product.images.length > 0) {
            setMainImage(product.images[0]);
        }
        setProductData({ ...product, referralLink });
    }, [product]);

    if (isLoading) return <Loading />;

    if (error) {
        navigate("/error");
    }

    const getPrevImage = (currentImage, images) => {
        const currentIndex = images.indexOf(currentImage);
        const prevIndex = (currentIndex - 1 + images.length) % images.length;
        return images[prevIndex];
    };

    const getNextImage = (currentImage, images) => {
        const currentIndex = images.indexOf(currentImage);
        const nextIndex = (currentIndex + 1) % images.length;
        return images[nextIndex];
    };

    const handleImageChange = (newImage, buttonType) => {
        setMainImage(newImage);
        setActiveButton(buttonType);
    };

    // Find index of current image in the product images array
    const images = product?.images || [];
    const currentIndex = images.indexOf(mainImage);
    // Show next button if its first image and show prev button if it's previous
    const isFirstImage = currentIndex === 0;
    const isLastImage = currentIndex === images.length - 1;

    return (
        <>
            <Helmet>
                <title>{product.name[language]} | OnlyBrands</title>
                <meta
                    property="og:title"
                    content={product.name[language]}
                    data-react-helmet="true"
                />
                <meta
                    property="og:description"
                    content="100%-ით ორიგინალი"
                    data-react-helmet="true"
                />
                <meta
                    property="og:image"
                    content={`${prefix}${product.images[0]}`}
                    data-react-helmet="true"
                />
                <meta property="og:image:width" content="1200" data-react-helmet="true" />
                <meta property="og:image:height" content="630" data-react-helmet="true" />
                <meta property="og:url" content={referralLink} data-react-helmet="true" />
                <meta property="og:type" content="product" data-react-helmet="true" />
                <meta property="og:site_name" content="OnlyBrands" data-react-helmet="true" />
                <link rel="canonical" href={referralLink} />
            </Helmet>
            {selectedImage && (
                <ImageModal
                    images={product?.images}
                    imageUrl={selectedImage}
                    onClose={handleCloseModal}
                />
            )}
            <Tagline />
            <Navbar navClass="defaultscroll is-sticky tagline-height" />
            <section className="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="flex flex-col mt-14">
                        <h3 className="text-3xl leading-normal font-semibold">
                            {product?.name?.[language]}
                        </h3>
                    </div>
                    <div className="relative mt-3">
                        <ul className="tracking-[0.5px] mb-0 inline-block">
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                                <Link to="/">{t.Onlybrands}</Link>
                            </li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                                <i className="mdi mdi-chevron-right"></i>
                            </li>
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                                <Link to="/shop">{t.store}</Link>
                            </li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                                <i className="mdi mdi-chevron-right"></i>
                            </li>
                            <li
                                className="inline-block uppercase text-[13px] font-bold text-orange-500"
                                aria-current="page"
                            >
                                {product?.name?.[language]}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container relative 2xl:px-7 lg:px-0 2xl:max-w-[1536px] lg:max-w-[1200px]">
                    {/* Main Flex Container */}
                    <div className="flex flex-col lg:flex-row gap-6 lg:items-start items-center">
                        {/* Images Section */}
                        <div className="flex flex-col lg:flex-1">
                            {/* Carousel and Main Image */}
                            <div className="flex flex-col lg:flex-row gap-6">
                                {/* Image Carousel */}
                                <div className="w-full lg:w-1/4 hidden lg:flex justify-center lg:justify-start">
                                    <ImageCarousel
                                        images={product?.images}
                                        setMainImage={setMainImage}
                                        mainImage={mainImage}
                                    />
                                </div>

                                {/* Main Image */}
                                <div className="w-full lg:w-3/4 relative">
                                    <div className="w-full h-80 sm:h-96 md:h-[500px] lg:h-[600px] overflow-hidden rounded-lg">
                                        <img
                                            src={
                                                mainImage?.includes("http")
                                                    ? mainImage
                                                    : `https://api.onlybrands.ge/storage/${mainImage}`
                                            }
                                            alt="Product"
                                            onClick={() => handleImageClick(mainImage)}
                                            className="w-full h-full object-contain cursor-pointer"
                                        />

                                        {/* Resize Icon */}
                                        <button
                                            onClick={() => handleImageClick(mainImage)}
                                            className="absolute top-2 right-2 bg-white border border-gray-300 rounded-full p-2 shadow-md hover:scale-110 transition-transform duration-150"
                                            aria-label="Open Image Modal"
                                        >
                                            <IoMdResize className="text-gray-600 h-3 w-3  md:h-5 md:w-5" />
                                        </button>

                                        {/* Desktop Buttons (Only shown on md:768px and above) */}
                                        <button
                                            onClick={() =>
                                                handleImageChange(
                                                    getPrevImage(mainImage, images),
                                                    "prev"
                                                )
                                            }
                                            className="hidden md:block absolute left-2 top-1/2 transform -translate-y-1/2 bg-white border border-gray-300 rounded-full p-2 shadow-md hover:scale-110 transition-transform duration-150"
                                            aria-label="Previous Image"
                                        >
                                            <IoIosArrowBack className="text-gray-600 h-5 w-5" />
                                        </button>
                                        <button
                                            onClick={() =>
                                                handleImageChange(
                                                    getNextImage(mainImage, images),
                                                    "next"
                                                )
                                            }
                                            className="hidden md:block absolute right-2 top-1/2 transform -translate-y-1/2 bg-white border border-gray-300 rounded-full p-2 shadow-md hover:scale-110 transition-transform duration-150"
                                            aria-label="Next Image"
                                        >
                                            <IoIosArrowForward className="text-gray-600 h-5 w-5" />
                                        </button>

                                        {/* Mobile Buttons (Only shown on screens smaller than 768px) */}
                                        <button
                                            onClick={() =>
                                                handleImageChange(
                                                    getPrevImage(mainImage, images),
                                                    "prev"
                                                )
                                            }
                                            className={`md:hidden absolute -left-2 top-1/2 transform -translate-y-1/2 bg-[#333] py-[13px] px-0.5 shadow-md transition-opacity duration-150 ${
                                                activeButton === "prev" ? "opacity-60" : ""
                                            }`}
                                            aria-label="Previous Image"
                                        >
                                            <IoIosArrowBack className="text-white h-8 w-6" />
                                        </button>
                                        <button
                                            onClick={() =>
                                                handleImageChange(
                                                    getNextImage(mainImage, images),
                                                    "next"
                                                )
                                            }
                                            className={`md:hidden absolute -right-2 top-1/2 transform -translate-y-1/2 bg-[#333] py-[13px] px-0.5 shadow-md transition-opacity duration-150 ${
                                                activeButton === "next" ? "opacity-60" : ""
                                            }`}
                                            aria-label="Next Image"
                                        >
                                            <IoIosArrowForward className="text-white h-8 w-6" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-1/4 flex lg:hidden justify-center lg:justify-start">
                            <ImageCarousel
                                images={product?.images}
                                setMainImage={setMainImage}
                                mainImage={mainImage}
                            />
                        </div>

                        {/* Product Details */}
                        <div className="w-full lg:w-1/4">
                            <ProductDetail product={product} />
                        </div>
                    </div>
                </div>
                <ArrivalItem data={data?.newArrivals} language={language} />
            </section>
            <Footer />
            <ScrollToTop />
        </>
    );
}
