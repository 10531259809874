import React, { useState } from "react";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import useCartStore from "../store/cartStore";
import { toast } from "sonner";
import useAuthMoadl from "../store/authWarningModal";
import useUserData from "../store/userStore";
import { handleGetLanguage } from "../utils/languageStorage";
import { checkQuantity } from "../api/cartApi";
import ShareButton from "./FaceBookShareButton";
import MobileAddToCartContainer from "./mobileAddToCartContainer";

export default function ProductDetail({ product }) {
    const [count, setCount] = useState(1);
    const [size, setSize] = useState("");
    const [colore, setColore] = useState(product.colors[0].id);
    const location = useLocation();

    const { addToCart } = useCartStore((state) => state);
    const { show } = useAuthMoadl((state) => state);
    const { user } = useUserData((state) => state);

    const navigate = useNavigate();
    const language = handleGetLanguage();

    const increments = () => {
        setCount(count + 1);
    };

    const decrements = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    };

    // const sizes = product.sizes;

    // const colors = product.colors;

    const translations = {
        en: {
            shopNow: "Shop Now",
            addToCart: "Add to Cart",
            productCode: "Code",
            size: "Size",
            quantity: "Quantity",
            colors: "Colors",
            youHaveToAdd: "You have to add color ",
            addedToCart: "You have added product to cart",
            digitalMarketing: "Digital Marketing Solutions for Tomorrow",
            experiencedMarketing: "Our Talented & Experienced Marketing Agency",
            createSkin: "Create your own skin to match your brand",
            shareText: "Share",
            price: "Price",
        },
        ka: {
            shopNow: "ყიდვა",
            addToCart: "კალათაში დამატება",
            productCode: "კოდი",
            size: "ზომა",
            quantity: "რაოდენობა",
            colors: "ფერები",
            youHaveToAdd: "ფერი  უნდა დაამატოთ",
            addedToCart: "პროდუქტი კალათაში დაემატა",
            digitalMarketing: "ციფრული მარკეტინგის გადაწყვეტილებები მომავლისთვის",
            experiencedMarketing: "ჩვენი გამოცდილი მარკეტინგის სააგენტო",
            createSkin: "შექმენით საკუთარი კანის ბრენდის შესაბამისი",
            shareText: "გააზიარე",
            price: "ფასი",
        },
    };

    const t = translations[language] || translations.en;

    const handleCopy = () => {
        if (!user.name || !user.referral_code) {
            show("signUp");
        } else {
            navigator.clipboard.writeText(
                `onlybrands.ge${location.pathname}?referral_code=${user.referral_code}`
            );
            toast.success(
                language === "ka"
                    ? "რეფერალური ლინკი დაკოპირებულია ✔"
                    : "Link code copied to clipboard ✔"
            );
        }
    };

    // other User Refferal from URL

    const queryParams = new URLSearchParams(location.search);
    const referralFromQuery = queryParams.get("referral_code");

    const setSingleReferalInSession = () => {
        referralFromQuery && sessionStorage.setItem("other_referral_code", referralFromQuery);
        const referral = sessionStorage.getItem("other_referral_code");
        return referral;
    };

    const handleShopNow = async () => {
        if (!user?.id || !user?.email) {
            show();
        } else {
            const isQuantity = await checkQuantity({
                id: product.id,
                colore: colore,
                quantity: count,
            });

            if (isQuantity === 200) {
                const referral = setSingleReferalInSession();
                const url = qs.stringifyUrl(
                    {
                        url: "/shop-checkout",
                        query: {
                            product: product?.id,
                            color: colore,
                            // size: size || undefined,
                            qtn: count,
                            referral: referral,
                        },
                    },
                    { skipNull: true, skipEmptyString: true }
                );
                navigate(url);
            }
        }
    };

    const handleAddToCart = () => {
        if (!user?.id || !user?.email) {
            show();
            return;
        }

        if (!colore) {
            toast.error(t.youHaveToAdd);
            return;
        }

        addToCart({
            product_id: product?.id,
            product: product,
            quantity: count,
            ...(referralFromQuery && referralFromQuery !== "" && { referral: referralFromQuery }),
            // size,
            color_id: colore,
            price: product?.discounted_price || product?.price,
        });

        setCount(1);
        setSize("");
    };

    console.log(product);

    const Row = ({ label, value }) => {
        return (
            <div className="flex items-center justify-between border-b-2 last:border-b-0">
                <div className={"2xl:text-sm text-xs"}>{label}</div>
                <div className={"2xl:text-sm text-xs"}>{value}</div>
            </div>
        );
    };

    return (
        <div className=" ">
            {/* Mobile Version Addtocart */}
            <MobileAddToCartContainer
                textOne={t.addToCart}
                textTwo={t.shopNow}
                handleAddToCart={handleAddToCart}
                handleShopNow={handleShopNow}
            />
            <div className={"md:hidden flex items-center justify-between"}>
                <h5 className="md:text-2xl text-base font-semibold">{product?.name?.[language]}</h5>
                <div className="md:hidden flex items-center gap-1">
                    <h5 className="md:text-lg text-xs font-semibold whitespace-nowrap">
                        {t.productCode} :
                    </h5>
                    <p className="text-slate-400 md:text-base text-xs whitespace-nowrap">
                        {product?.overview?.[language]}
                    </p>
                </div>
            </div>
            <div className={"md:hidden flex items-center justify-between mt-2"}>
                <span className="text-slate-800 dark:text-white font-bold md:text-2xl text-base me-1 ">
                    {product?.discounted_price}GEL{" "}
                    {product?.price && <del className="text-red-600"> {product?.price}GEL</del>}
                </span>
                <button
                    onClick={handleCopy}
                    className=" whitespace-nowrap py-1 2xl:px-5 px-1 md:hidden font-semibold tracking-wide 2xl:text-sm text-[10px] text-center bg-orange-500 text-white rounded-md hover:bg-orange-400"
                >
                    {language === "ka" ? "გააზიარე რეფერალი" : "Share Referral"}
                </button>
                <ShareButton
                    text={t.shareText}
                    language={language}
                    product={product}
                    referralLink={`onlybrands.ge${location.pathname}?referral_code=${user.referral_code}`}
                    className={"flex md:hidden px-1 !bg-[red]"}
                />
            </div>
            <h5 className="text-2xl font-semibold hidden md:flex">{product?.name?.[language]}</h5>
            <div className="mt-2 hidden md:flex items-center gap-2">
                <h5 className="text-lg font-semibold">{t.price} : </h5>
                <span className="text-slate-800 dark:text-white font-bold text-2xl me-1 ">
                    {product?.discounted_price}GEL{" "}
                    {product?.price && <del className="text-red-600"> {product?.price}GEL</del>}
                </span>
            </div>

            <div className="mt-4 md:flex hidden items-baseline gap-2">
                <h5 className="text-lg font-semibold">{t.productCode} :</h5>
                <p className="text-slate-400 mt-2">{product?.overview?.[language]}</p>
            </div>

            <div className="grid  grid-cols-1 gap-6 mt-4">
                <div className="flex items-center whitespace-nowrap">
                    <h5 className="md:text-lg text-base font-semibold me-2 text-center ">
                        {t.quantity}:
                    </h5>
                    <div className="qty-icons ms-3 space-x-0.5">
                        <button
                            onClick={() => decrements()}
                            className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white minus"
                        >
                            -
                        </button>
                        <input
                            min="0"
                            name="quantity"
                            value={count}
                            onChange={() => {}}
                            type="number"
                            className="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white pointer-events-none w-16 ps-4 quantity"
                        />
                        <button
                            onClick={() => increments()}
                            className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white plus"
                        >
                            +
                        </button>
                    </div>
                </div>

                {/*<div className="flex items-center">*/}
                {/*    <h5 className="text-lg font-semibold me-2">{t.colors}:</h5>*/}
                {/*    <div className="space-x-2">*/}
                {/*        {colors &&*/}
                {/*            colors.map((item, i) => (*/}
                {/*                <button*/}
                {/*                    onClick={() => {*/}
                {/*                        setColore(item?.id);*/}
                {/*                    }}*/}
                {/*                    key={i}*/}
                {/*                    style={{backgroundColor: item?.name}}*/}
                {/*                    className={`size-6 rounded-full ring-2  dark:ring-slate-800  inline-flex align-middle ${*/}
                {/*                        colore === item.id*/}
                {/*                            ? "ring-black dark:ring-white"*/}
                {/*                            : "ring-gray-200"*/}
                {/*                    }`}*/}
                {/*                    title={item?.name}*/}
                {/*                ></button>*/}
                {/*            ))}*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="mt-4 space-x-1 md:flex hidden">
                {/*<button*/}
                {/*    onClick={handleShopNow}*/}
                {/*    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2 hover:bg-orange-400"*/}
                {/*>*/}
                {/*    {t.shopNow}*/}
                {/*</button>*/}

                <button
                    onClick={() => {
                        if (!user?.id || !user?.email) {
                            show();
                            return;
                        }

                        if (!colore) {
                            toast.error(t.youHaveToAdd);
                            return;
                        }

                        addToCart({
                            product_id: product?.id,
                            product: product,
                            quantity: count,
                            ...(referralFromQuery &&
                                referralFromQuery !== "" && { referral: referralFromQuery }),
                            // size,
                            color_id: colore,
                            price: product?.discounted_price || product?.price,
                        });

                        setCount(1);
                        setSize("");
                    }}
                    className="w-full py-2 px-5 inline-block font-semibold tracking-wide align-middle 2xl:text-sm text-xs text-center rounded-md bg-orange-500 hover:bg-orange-400 text-white mt-2 active:opacity-50"
                >
                    {t.addToCart}
                </button>
            </div>
            <button
                onClick={handleShopNow}
                className="hidden w-full py-2 px-5 md:inline-block font-semibold tracking-wide align-middle 2xl:text-sm text-xs text-center bg-orange-500 text-white rounded-md mt-4 hover:bg-orange-400"
            >
                {t.shopNow}
            </button>
            <div className={"flex flex-row gap-2 mt-3"}>
                <ShareButton
                    text={t.shareText}
                    language={language}
                    product={product}
                    referralLink={`onlybrands.ge${location.pathname}?referral_code=${user.referral_code}`}
                    className={"md:flex hidden"}
                />
                <button
                    onClick={handleCopy}
                    className="w-full whitespace-nowrap py-2 2xl:px-5 px-3 md:inline-block hidden font-semibold tracking-wide align-middle 2xl:text-sm text-xs text-center bg-orange-500 text-white rounded-md hover:bg-orange-400"
                >
                    {language === "ka" ? "გააზიარე რეფერალი" : "Share Referral"}
                </button>
            </div>

            <div className={"mt-4 border-2"}>
                <Row
                    label={language === "ka" ? "ბრენდი" : "Brand"}
                    value={product?.name?.[language]}
                />
                <Row
                    label={language === "ka" ? "სტატუსი" : "Status"}
                    value={product?.status?.[language]}
                />
                <Row
                    label={language === "ka" ? "იმპორტირებული" : "Imported"}
                    value={product?.country?.name[language]}
                />
                <Row
                    label={language === "ka" ? "კატეგორია" : "Category"}
                    value={product?.categories[0]?.name?.[language]}
                />
                <Row
                    label={language === "ka" ? "სქესი" : "Sex"}
                    value={product?.genders[0]?.name?.[language]}
                />
                <Row
                    label={language === "ka" ? "შემადგენლობა" : "Composition"}
                    value={product?.composition?.[language]}
                />
                <Row
                    label={language === "ka" ? "ფერი" : "Colore"}
                    value={product?.colors[0]?.name?.[language]}
                />
                {/* <Row label="ზომა" value="ზომა" /> */}
            </div>
        </div>
    );
}
